// // singleTableCopy.js
const singleTableCopy = {
  targets: [
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-20"],
        sourceCols: [1],
        targetRows: ["row-20"],
        targetCols: [2],
      },
    },

    // for the 3 columns stated hear
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-20"],
        sourceCols: [3],
        targetRows: ["row-20"],
        targetCols: [4],
      },
    },

    // sixth row atated hear

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-15"],
        sourceCols: [6],
        targetRows: ["row-15"],
        targetCols: [7],
      },
    },
    // from hear the 8 will stated

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-15"],
        sourceCols: [8],
        targetRows: ["row-15"],
        targetCols: [9],
      },
    },

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-31"],
        sourceCols: [7],
        targetRows: ["row-31"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-31"],
        sourceCols: [9],
        targetRows: ["row-31"],
        targetCols: [4],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-29"],
        sourceCols: [2],
        targetRows: ["row-34"],
        targetCols: [7],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-29"],
        sourceCols: [4],
        targetRows: ["row-34"],
        targetCols: [9],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-71"],
        sourceCols: [9],
        targetRows: ["row-71"],
        targetCols: [4],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-71"],
        sourceCols: [7],
        targetRows: ["row-71"],
        targetCols: [2],
      },
    },
    //

    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-74"],
        sourceCols: [1],
        targetRows: ["row-74"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-74"],
        sourceCols: [3],
        targetRows: ["row-74"],
        targetCols: [4],
      },
    },
    //Extraordinary items and
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-69"],
        sourceCols: [2],
        targetRows: ["row-76"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-69"],
        sourceCols: [2],
        targetRows: ["row-76"],
        targetCols: [7],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-69"],
        sourceCols: [4],
        targetRows: ["row-76"],
        targetCols: [9],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-69"],
        sourceCols: [4],
        targetRows: ["row-76"],
        targetCols: [4],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-78"],
        sourceCols: [1],
        targetRows: ["row-78"],
        targetCols: [2],
      },
    },
    {
      source: "vertical-profit-loss",
      mapping: {
        sourceRows: ["row-78"],
        sourceCols: [3],
        targetRows: ["row-78"],
        targetCols: [4],
      },
    },

    // this is for the vertical-annexures-assets----------------------------------------------------------------
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-5"],
        sourceCols: [9],
        targetRows: ["row-5"],
        targetCols: [10],
      },
    },
    //
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-5"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        targetRows: ["row-6"],
        targetCols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-5"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        targetRows: ["row-6"],
        targetCols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-13"],
        sourceCols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        targetRows: ["row-14"],
        targetCols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7"],
        sourceCols: [9],
        targetRows: ["row-7"],
        targetCols: [10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-8"],
        sourceCols: [9],
        targetRows: ["row-8"],
        targetCols: [10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10"],
        sourceCols: [9],
        targetRows: ["row-10"],
        targetCols: [10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-11"],
        sourceCols: [9],
        targetRows: ["row-11"],
        targetCols: [10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15"],
        sourceCols: [9],
        targetRows: ["row-15"],
        targetCols: [10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-16"],
        sourceCols: [9],
        targetRows: ["row-16"],
        targetCols: [10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18"],
        sourceCols: [9],
        targetRows: ["row-18"],
        targetCols: [10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-19"],
        sourceCols: [9],
        targetRows: ["row-19"],
        targetCols: [10],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-140"],
        sourceCols: [1],
        targetRows: ["row-141"],
        targetCols: [1],
      },
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-140"],
        sourceCols: [2],
        targetRows: ["row-141"],
        targetCols: [2],
      },
    },
  ],
};

export default singleTableCopy;
